<template>
	<v-timeline-item v-bind="$attrs" :color="color">
		<v-hover v-slot="{ hover }">
			<v-card>
				<v-scroll-y-transition>
					<v-btn
						v-if="hover && ($root.acceso('DEV') || (
							$root.acceso('CONTROL_IMPAGADOS')
							&&
							$store.getters.getDecodedJwtEmpresa.idUsuario == message.idUsuario
							&&
							new Date().getTime() <= (Number(message.fecha) + (1000 * 60 * 5)))
						)"
						@click.stop="eliminar"
						class="float-right"
						icon
						small
						color="error"
					>
						<v-icon small>mdi-delete</v-icon>
					</v-btn>
				</v-scroll-y-transition>

				<v-card-subtitle class="py-2">
					{{ message.Nombre }}
					<v-icon
						small
						color="primary"
						v-if="
							$store.getters.getDecodedJwtEmpresa.idUsuario == message.idUsuario
						"
					>mdi-account</v-icon>
					-
					{{
						new Date(Number(message.fecha)).toLocaleString()
					}}
					<status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" />
					<v-icon v-if="Date.now() - parseDate(message.fecha, true) < (1000 * 3600 * 24 * 1.5)" >mdi-circle-small</v-icon>
				</v-card-subtitle>

				<div class="px-4 py-1" v-html="message.mensaje" />
				<div class="d-flex flex-wrap" v-if="message.children.length > 0">
					<v-chip
						label
						@click="descargarArchivo(child.archivo)"
						v-for=" child in message.children "
						:key="child.idMensaje"
						color="primary"
						outlined
						class="ma-1 mt-0"
					>
						<v-icon left>mdi-download</v-icon>
						{{ child.archivo.substring(child.archivo.indexOf("_") + 1) }}
					</v-chip>
				</div>
			</v-card>
		</v-hover>
	</v-timeline-item>
</template>

<script>
import { downloadFile, parseDate } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
	props: {
		message: Object
	},
	components: {
		StatusChip: () => import('@/components/StatusChip.vue')
	},
	computed: {
		color() {
			let color;
			if (this.message.IdCarteraCobro) color = 'primary'
			if (this.message.IdCliente) color = 'secondary'
			return color;
		}
	},
	methods: {
		parseDate,
		descargarArchivo(name) {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				responseType: "blob",
				params: {
					name,
					token: this.$store.getters.getJwtEmpresa
				},
			}).then((res) => {
				let extension = name.split(".")
				[name.split(".").length - 1].toLowerCase();

				let file = new File(
					[res.data],
					name.substring(name.indexOf("_") + 1),
					{
						type: extensions[extension],
					}
				);

				let download = true
				if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) download = false

				downloadFile(file, !download);
				this.downloadingFile = false;
				// window.open(fileURL);
			});
		},
		eliminar() {
			if (!confirm('¿Seguro que quieres eliminar este mensaje?')) return;
			axios({
				method: "delete",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				data: {
					idMensaje: this.message.idMensaje,
					token: this.$store.getters.getJwtEmpresa
				},
			}).then(res => this.$emit('reload'))
		}
	}
}
</script>